<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <polyline
      style="fill:#65B4BB;"
      points="359.402,152.421 404.556,152.421 404.556,512 107.441,512 107.441,152.421 152.606,152.421 
	"
    />
    <rect
      x="107.444"
      y="152.427"
      style="fill:#57A3A7;"
      width="297.115"
      height="90.049"
    />
    <path
      style="fill:#FED159;"
      d="M330.519,0H181.486c-15.95,0-28.88,12.93-28.88,28.88v170.423h206.792V28.88
	C359.398,12.93,346.469,0,330.519,0z"
    />
    <rect
      x="154.505"
      y="274.218"
      style="fill:#A0DBFD;"
      width="203.006"
      height="53.52"
    />
    <g>
      <rect
        x="154.505"
        y="364.267"
        style="fill:#57A3A7;"
        width="46.463"
        height="41.439"
      />
      <rect
        x="311.041"
        y="364.267"
        style="fill:#57A3A7;"
        width="46.463"
        height="41.439"
      />
      <rect
        x="232.767"
        y="364.267"
        style="fill:#57A3A7;"
        width="46.463"
        height="41.439"
      />
      <rect
        x="154.505"
        y="430.004"
        style="fill:#57A3A7;"
        width="46.463"
        height="41.439"
      />
      <rect
        x="311.041"
        y="430.004"
        style="fill:#57A3A7;"
        width="46.463"
        height="41.439"
      />
      <rect
        x="232.767"
        y="430.004"
        style="fill:#57A3A7;"
        width="46.463"
        height="41.439"
      />
    </g>
    <rect x="275.989" style="fill:#65B4BB;" width="42.225" height="199.303" />
    <polygon
      style="fill:#C1E9FD;"
      points="357.506,274.219 357.506,327.739 228.874,327.739 263.89,274.219 "
    />
    <g>
      <rect
        x="226.615"
        y="32.1"
        style="fill:#F6C454;"
        width="20.299"
        height="99.653"
      />
      <rect
        x="188.782"
        y="32.1"
        style="fill:#F6C454;"
        width="20.299"
        height="57.211"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
